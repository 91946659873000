import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min';

// FIXME: DOMContentLoaded scope化

/************************************************************
 * エレメントの定義
 ************************************************************/

// 各入力フィールドのタイトル
const clinicalAreaTitle = document.getElementById('clinical_area_title'); // 診療領域
const clinicalDepartmentTitle = document.getElementById(
  'clinical_department_title'
); // 診療科目
const otherQualificationTitle = document.getElementById(
  'other_qualification_title'
); // その他医療資格
const facilityTitle = document.getElementById('facility_title'); // 勤務先施設

// 入力フィールドのコンテンツ
const facilityContents = document.getElementById('facility_contents'); // 勤務先施設

// 各入力フィールド
const clinicalAreaInput = document.getElementById('clinical_area_input'); // 診療領域
const clinicalDepartmentInput = document.getElementById(
  'clinical_department_input'
); // 診療科目
const nameKanaElems = document.querySelectorAll('[id$=name_kana]'); // 姓名（フリガナ）

// 医療資格 ラジオボタン群 [医師, 看護師・准看護師, 薬剤師, その他]
const qualificationDoctorInput = document.getElementById(
  'qualification_doctor_input'
); // 医療資格>医師
const qualificationPharmacistInput = document.getElementById(
  'qualification_pharmacist_input'
); // 医療資格>薬剤師
const qualificationNurseInput = document.getElementById(
  'qualification_nurse_input'
);
const qualificationOtherInput = document.getElementById(
  'qualification_other_input'
); // 医療資格>その他
const qualificationInputs = [
  qualificationDoctorInput,
  qualificationNurseInput,
  qualificationPharmacistInput,
  qualificationOtherInput,
].filter(Boolean);

const otherQualificationInput = document.getElementById(
  'other_qualification_input'
); // その他医療資格
const clinicRadio = document.getElementById('clinic_radio'); // 診療所のラジオボタングループ
const pharmacyRadio = document.getElementById('pharmacy_radio'); // 薬局のラジオボタングループ

// 施設タイプ ラジオボタン群 [病院, 診療所, 薬局, その他]
const facilityTypeHospitalInput = document.getElementById(
  'facility_type_hospital_input'
); // 勤務先施設>病院
const facilityTypeClinicInput = document.getElementById(
  'facility_type_clinic_input'
); // 勤務先施設>診療所
const facilityTypePharmacyInput = document.getElementById(
  'facility_type_pharmacy_input'
); // 勤務先施設>薬局
const facilityTypeOtherInput = document.getElementById(
  'facility_type_other_input'
); // 勤務先施設>その他
const facilityTypeInputs = [
  facilityTypeHospitalInput,
  facilityTypeClinicInput,
  facilityTypePharmacyInput,
  facilityTypeOtherInput,
].filter(Boolean);

const facilityPrefectureInput = document.getElementById(
  'facility_prefecture_input'
); // 都道府県
const facilityMunicipalityInput = document.getElementById(
  'facility_municipality_input'
); // 市区町村
const customerCodeInput = document.getElementById('customer_code_input'); // お得意先コード
const facilityNameInputSelect = document.getElementById(
  'facility_name_input_select'
); // 勤務先施設（選択式）
const facilityNameInputSelectWrap = document.getElementById(
  'facility_name_input_select_wrap'
); // 勤務先施設（選択式）
const facilityNameInputTxt = document.getElementById('facility_name_input_txt'); // 勤務先施設（自由入力）
const facilityPostalcodeInput = document.getElementById(
  'facility_postalcode_input'
); // 郵便番号
const facilityStreetAddressInput = document.getElementById(
  'facility_street_address_input'
); // 番地以下
const facilityTelInput = document.getElementById('facility_tel_input'); // 電話番号
const facilityTelComment = document.getElementById('facility_tel_comment');
const facilityDepartmentInput = document.getElementById(
  'facility_department_input'
); // 所属部署
const facilityPositionInput = document.getElementById(
  'facility_position_input'
); // 役職

const email = document.getElementById('email_register'); // メールアドレス
const emailConfirm = document.getElementById('email_register_confirm'); // メールアドレス（再確認）
const passwordRegister = document.getElementById('password_register'); // パスワード
const passwordRegisterConfirm = document.getElementById(
  'password_register_confirm'
); // パスワード（再確認）

// 各入力フィールドのタイトル
const facilityDepartmentTitle = document.getElementById(
  'facility_department_title'
);
const facilityPositionTitle = document.getElementById(
  'facility_position_title'
);
const customerCodeTitle = document.getElementById('customer_code_title');
const facilityPostalcodeTitle = document.getElementById(
  'facility_postalcode_title'
); // 郵便番号
const facilityStreetAddressTitle = document.getElementById(
  'facility_street_address_title'
); // 番地以下
const facilityTelTitle = document.getElementById('facility_tel_title'); // 電話番号
const facilityNameChangeBtn = document.getElementById(
  'facility_name_change_btn'
); // 勤務先施設名入力形式切り替えボタン
const facilityNameChangeBtnWrap = document.getElementById(
  'facility_name_change_btn_wrap'
); // 勤務先施設名入力形式切り替えボタン一式
// 勤務先施設名入力形式切り替え用 現在の入力形式 enum
const currentInputType = { select: 1, text: 2 };
/************************************************************
 * メソッドの定義
 ************************************************************/
/**
 * 各フォームの有効化処理
 * @param {HTMLElement[]} input 対象のエレメント
 * @param {String=} type selectやinput等の入力形式
 * @param {Boolean=} required 入力必須ならtrue
 */
function inputActivator(inputs, type = 'input', required = false) {
  inputs.forEach((input) => {
    input.disabled = false;
    input.hidden = false;
    if (type != 'select') {
      input.required = required;
      input.classList.remove('gray-out', 'gray-out--border');
    }
    switch (type) {
      case 'select': {
        const placeholder = input.parentElement.getElementsByClassName(
          'choices__placeholder'
        )[0];
        if (placeholder != null) {
          placeholder.innerHTML = placeholder.innerHTML.replace(
            '選択不要です',
            '選択してください'
          );
        }
        input.choices.enable();
        break;
      }
      case 'radio':
        switch (input) {
          case facilityTypePharmacyInput:
            inputActivator([pharmacyRadio]);
            break;
          case facilityTypeClinicInput:
            inputActivator([clinicRadio]);
            break;
        }
        break;
      default:
        // default input
        switch (input) {
          case facilityPostalcodeInput:
            input.placeholder = '例）0000000';
            break;
          case facilityStreetAddressInput:
            input.placeholder = '例）恵比寿2-36-13';
            break;
          case facilityTelInput:
            input.placeholder = '例）08012345678';
            break;
          default:
            input.placeholder = '';
            break;
        }
    }
  });
}

/**
 * 各フォームタイトルの有効化処理
 * @param {HTMLElement[]} title 対象のエレメント
 * @param {Boolean=} required 入力必須ならtrue
 */
function titleActivator(titles, required = false) {
  titles.forEach((input) => {
    if (required) {
      input.classList.remove('gray-out');
      input.classList.add('required-title');
    } else {
      input.classList.remove('gray-out', 'gray-out--border');
    }
  });
}

/**
 * 各フォームの無効化処理
 * @param {HTMLElement[]} input 対象のエレメント
 * @param {String=} type selectやinput等の入力形式
 */
function inputInactivator(inputs, type = 'input') {
  inputs.forEach((input) => {
    input.required = false;
    input.disabled = true;
    if (type != 'select') {
      input.classList.add('gray-out', 'gray-out--border');
    }
    switch (type) {
      case 'select': {
        input.choices.clearChoices();
        input.choices.destroy();
        input.choices.init();
        input.choices.removeActiveItems(); // 選択解除
        input.choices.setChoiceByValue(['', '選択不要です']);
        const placeholder = input.parentElement.getElementsByClassName(
          'choices__placeholder'
        )[0];
        if (placeholder != null) {
          placeholder.innerHTML = placeholder.innerHTML.replace(
            '選択してください',
            '選択不要です'
          );
        }
        input.choices.disable();
        break;
      }
      case 'radio':
        input.checked = false;
        switch (input) {
          case facilityTypePharmacyInput:
            inputInactivator([pharmacyRadio]);
            break;
          case facilityTypeClinicInput:
            inputInactivator([clinicRadio]);
            break;
        }
        break;
      default:
        input.placeholder = '入力不要です';
        input.value = '';
    }
  });
}

/**
 * 各フォームタイトルの無効化処理
 * @param {HTMLElement[]} title 対象のエレメント
 */
function titleInactivator(titles) {
  titles.forEach((input) => {
    input.classList.remove('required-title');
    input.classList.add('gray-out', 'gray-out--border');
  });
}

/**
 * 医療資格や勤務先施設に応じて、勤務先施設名の「選択」と「手入力」を切り替える
 * @param {String} type inputの形式
 */
function facilityNameFieldChanger(type) {
  if (type === 'txt') {
    // 勤務先施設名を自由入力に
    facilityNameInputSelectWrap.classList.remove('show');
    facilityNameInputSelectWrap.classList.add('hide');
    facilityNameInputSelect.disabled = true;
    facilityNameInputSelect.choices.removeActiveItems();
    facilityNameInputTxt.classList.remove('hide');
    facilityNameInputTxt.classList.add('show');
    facilityNameInputTxt.required = true;
    // ボタンを切り替え
    facilityNameChangeBtn.value = currentInputType.text;
    facilityNameChangeBtn.innerHTML = '勤務先施設名を選択式に戻す';
  } else if (type === 'select') {
    // 勤務先施設名を選択式に
    facilityNameInputTxt.classList.remove('show');
    facilityNameInputTxt.classList.add('hide');
    facilityNameInputTxt.required = false;
    facilityNameInputTxt.value = '';
    facilityNameInputSelectWrap.classList.remove('hide');
    facilityNameInputSelectWrap.classList.add('show');
    facilityNameInputSelect.disabled = false;
    // ボタンを切り替え
    facilityNameChangeBtn.value = currentInputType.select;
    facilityNameChangeBtn.innerHTML = '勤務先施設名を手入力に切り替える';
  }
}

/**
 * 入力値の英数記号を半角に変換してリターン
 * @param {String} str 入力文字列
 * @returns {String} 半角変換された文字列
 */
function halfConverter(str) {
  let result = '';
  const words = str.split('');
  const exceptions = {
    ー: '-',
    '〜': '~',
    '”': '"',
    '’': "'",
    '‘': '`',
    '￥': '\\',
    '　': ' ',
    '＝': '=',
    '｜': '|',
    '＋': '+',
    '＊': '*',
    '＜': '<',
    '＞': '>',
    '？': '?',
    '！': '!',
    '＃': '#',
    '＄': '$',
    '％': '%',
    '＆': '&',
    '＠': '@',
    '。': '.',
  };
  words.forEach((word) => {
    if (word.search(/[Ａ-Ｚａ-ｚ０-９]/g) == 0) {
      result += String.fromCharCode(word.charCodeAt(0) - 65248);
    } else {
      if (word in exceptions) {
        result += exceptions[word];
      } else {
        result += word;
      }
    }
  });
  return result;
}

/**
 * 姓名（フリガナ） を全角へ
 * プロフィール変更でも適用するため、多少遅くなるが「querySelectorAll」で部分一致
 */
Array.prototype.forEach.call(nameKanaElems, (nameKanaElem) => {
  nameKanaElem.addEventListener('change', () => {
    toFullWidth.convert(this.id);
  });
});

// 半角カナ => 全角カナ コンバーター
const toFullWidth = {
  /**
   * 入力値の半角カタカナを全角に変換してリターン
   * @param {String} inputId イベントを発火させたフォームのid
   * @returns {String} 全角変換された文字列
   */
  convert: (inputId) => {
    const str = document.getElementById(inputId).value;
    document.getElementById(inputId).value = this.replace(str);
  },
  replace: (str) => {
    const replaceWords = {
      ｳﾞ: 'ヴ',
      ｶﾞ: 'ガ',
      ｷﾞ: 'ギ',
      ｸﾞ: 'グ',
      ｹﾞ: 'ゲ',
      ｺﾞ: 'ゴ',
      ｻﾞ: 'ザ',
      ｼﾞ: 'ジ',
      ｽﾞ: 'ズ',
      ｾﾞ: 'ゼ',
      ｿﾞ: 'ゾ',
      ﾀﾞ: 'ダ',
      ﾁﾞ: 'ヂ',
      ﾂﾞ: 'ヅ',
      ﾃﾞ: 'デ',
      ﾄﾞ: 'ド',
      ﾊﾞ: 'バ',
      ﾋﾞ: 'ビ',
      ﾌﾞ: 'ブ',
      ﾍﾞ: 'ベ',
      ﾎﾞ: 'ボ',
      ﾊﾟ: 'パ',
      ﾋﾟ: 'ピ',
      ﾌﾟ: 'プ',
      ﾍﾟ: 'ペ',
      ﾎﾟ: 'ポ',
      ｱ: 'ア',
      ｲ: 'イ',
      ｳ: 'ウ',
      ｴ: 'エ',
      ｵ: 'オ',
      ｶ: 'カ',
      ｷ: 'キ',
      ｸ: 'ク',
      ｹ: 'ケ',
      ｺ: 'コ',
      ｻ: 'サ',
      ｼ: 'シ',
      ｽ: 'ス',
      ｾ: 'セ',
      ｿ: 'ソ',
      ﾀ: 'タ',
      ﾁ: 'チ',
      ﾂ: 'ツ',
      ﾃ: 'テ',
      ﾄ: 'ト',
      ﾅ: 'ナ',
      ﾆ: 'ニ',
      ﾇ: 'ヌ',
      ﾈ: 'ネ',
      ﾉ: 'ノ',
      ﾊ: 'ハ',
      ﾋ: 'ヒ',
      ﾌ: 'フ',
      ﾍ: 'ヘ',
      ﾎ: 'ホ',
      ﾏ: 'マ',
      ﾐ: 'ミ',
      ﾑ: 'ム',
      ﾒ: 'メ',
      ﾓ: 'モ',
      ﾔ: 'ヤ',
      ﾕ: 'ユ',
      ﾖ: 'ヨ',
      ﾗ: 'ラ',
      ﾘ: 'リ',
      ﾙ: 'ル',
      ﾚ: 'レ',
      ﾛ: 'ロ',
      ﾜ: 'ワ',
      ｦ: 'ヲ',
      ﾝ: 'ン',
      ｧ: 'ァ',
      ｨ: 'ィ',
      ｩ: 'ゥ',
      ｪ: 'ェ',
      ｫ: 'ォ',
      ｬ: 'ャ',
      ｭ: 'ュ',
      ｮ: 'ョ',
      ｯ: 'ッ',
      '､': '、',
      '｡': '。',
      ｰ: 'ー',
      '｢': '「',
      '｣': '」',
      ﾞ: '”',
      ﾟ: '',
    };

    for (const key in replaceWords) {
      str = str.replace(new RegExp(key, 'g'), replaceWords[key]);
    }

    return str;
  },
};

/************************************************************
 * イベント
 ************************************************************/
/**
 * ユーザ登録画面 各Field有効/無効制御 戻ってきた時用の制御
 */

(() => {
  if (clinicalAreaInput != null) {
    applyChoices();
  }
  const submitButton = document.getElementById('submit_step1');
  if (submitButton != null) {
    // Event発生順序: click, form validation, submit
    submitButton.addEventListener('click', validateForm);
  }
  function validateForm() {
    // validate select boxes manually
    document.querySelectorAll('select').forEach((it) => {
      if (!it.disabled && !it.value) {
        // enabled && not selected
        it.required = true;
        it.checkValidity();
      } else {
        it.required = false;
      }
    });
  }

  // 医療資格選択イベント
  if (qualificationInputs.length) {
    qualificationEvent();
    qualificationInputs.forEach((it) => {
      it.addEventListener('change', qualificationEvent);
    });
  }

  // 診療領域が選択されたら診療科目selectboxをセットする
  if (clinicalAreaInput) {
    clinicalAreaInput.addEventListener('change', setClinicalDepartments, false);
  }

  // 施設タイプ選択イベント
  if (facilityTypeInputs.length) {
    facilityEvent();
    facilityTypeInputs.forEach((it) => {
      it.addEventListener('change', facilityEvent);
    });
  }

  // 勤務先施設名(手入力)が入力されていたら手入力モードに設定する
  if (facilityNameInputTxt && facilityNameInputTxt.value) {
    facilityNameFieldChanger('txt');
  }

  // 都道府県が選択されたら市区町村、勤務先施設selectboxをセットする
  if (facilityPrefectureInput) {
    facilityPrefectureInput.addEventListener('change', setMunicipalities, false);
    facilityPrefectureInput.addEventListener('change', setFacilities, false);
  }

  // 市区町村が選択されたら勤務先施設selectboxをセットする
  if (facilityMunicipalityInput) {
    facilityMunicipalityInput.addEventListener('change', setFacilities, false);
  }

  // 本人確認方法選択イベント
  const verificationMethodEnrollment = document.querySelector('[id$=verification_method_enrollment]');
  const verificationMethodRegistrationNumber = document.querySelector('[id$=registration_number]');
  const verificationMethodRadios = [
    verificationMethodEnrollment, verificationMethodRegistrationNumber
  ].filter(Boolean);
  if (verificationMethodRadios.length) {
    switchByVerificationMethod();
    verificationMethodRadios.forEach((it) => {
      it.addEventListener('change', switchByVerificationMethod);
    });
  }
})();

function qualificationEvent() {
  // 施設タイプ入力可否制御
  setFacilityTypeEnabled();

  // 医師
  if (qualificationDoctorInput.checked) {
    // 診療領域・診療科目を入力必須に
    inputActivator(
      [clinicalAreaInput, clinicalDepartmentInput],
      'select',
      true
    );
    titleActivator([clinicalAreaTitle, clinicalDepartmentTitle], true);
  } else {
    // 診療領域・診療科目を入力不可に
    inputInactivator([clinicalAreaInput, clinicalDepartmentInput], 'select');
    titleInactivator([clinicalAreaTitle, clinicalDepartmentTitle]);
  }

  // 薬剤師
  if (qualificationPharmacistInput.checked) {
    // 郵便番号、番地以下、電話番号を必須入力に
    inputActivator(
      [facilityPostalcodeInput, facilityStreetAddressInput, facilityTelInput],
      'input',
      true
    );
    titleActivator(
      [facilityPostalcodeTitle, facilityStreetAddressTitle, facilityTelTitle],
      true
    );
    // 所属部署、役職、お得意先コードを入力可能に
    inputActivator([
      facilityDepartmentInput,
      facilityPositionInput,
      customerCodeInput,
    ]);
    titleActivator([
      facilityDepartmentTitle,
      facilityPositionTitle,
      customerCodeTitle,
    ]);
  } else {
    inputInactivator([customerCodeInput]);
    titleInactivator([customerCodeTitle]);
  }

  // 医療資格その他
  if (qualificationOtherInput.checked) {
    // その他医療資格、郵便番号、番地以下、電話番号を必須入力に
    inputActivator([otherQualificationInput], 'select', true);
    inputActivator(
      [facilityPostalcodeInput, facilityStreetAddressInput, facilityTelInput],
      'input',
      true
    );
    titleActivator(
      [
        otherQualificationTitle,
        facilityPostalcodeTitle,
        facilityStreetAddressTitle,
        facilityTelTitle,
      ],
      true
    );
    // 所属部署、役職を入力可能に
    inputActivator([facilityDepartmentInput, facilityPositionInput]);
    titleActivator([facilityDepartmentTitle, facilityPositionTitle]);
  } else {
    // その他医療資格を入力不可に
    inputInactivator([otherQualificationInput], 'select');
    titleInactivator([otherQualificationTitle]);
  }

  // 薬剤師かその他
  if (qualificationPharmacistInput.checked || qualificationOtherInput.checked) {
    // 勤務先施設名を自由入力に
    facilityNameFieldChanger('txt');
    // 勤務先施設名の切り替えボタンを非表示に
    facilityNameChangeBtnWrap.classList.remove('show');
    facilityNameChangeBtnWrap.classList.add('hide');
  } else {
    // 勤務先施設名(手入力)が未入力なら勤務先施設名を選択式に
    if (!facilityNameInputTxt.value) {
      facilityNameFieldChanger('select');
    }
    // 勤務先施設名の切り替えボタンを表示
    facilityNameChangeBtnWrap.classList.remove('hide');
    facilityNameChangeBtnWrap.classList.add('show');

    if (!facilityTypeOtherInput.checked) {
      // その他医療資格、郵便番号、番地以下、電話番号、所属部署、役職を入力不可に
      inputInactivator([otherQualificationInput], 'select');
      inputInactivator([
        facilityPostalcodeInput,
        facilityStreetAddressInput,
        facilityTelInput,
        facilityDepartmentInput,
        facilityPositionInput,
      ]);
      titleInactivator([
        otherQualificationTitle,
        facilityPostalcodeTitle,
        facilityStreetAddressTitle,
        facilityTelTitle,
        facilityDepartmentTitle,
        facilityPositionTitle,
      ]);
    }
  }

  switchVerificationWrappers();
}

/**
 * 勤務先施設の選択によるイベント
 */
facilityTypeInputs.forEach((input) => {
  if (input !== null) {
    input.addEventListener('input', facilityEvent);
    input.addEventListener('input', setFacilities);
  }
});

function facilityEvent() {
  // 勤務先施設その他
  if (facilityTypeOtherInput.checked) {
    inputActivator(
      [facilityPostalcodeInput, facilityStreetAddressInput, facilityTelInput],
      'input',
      true
    );
    inputActivator([facilityDepartmentInput, facilityPositionInput]);
    titleActivator(
      [facilityPostalcodeTitle, facilityStreetAddressTitle, facilityTelTitle],
      true
    );
    titleActivator([facilityDepartmentTitle, facilityPositionTitle]);
    // 勤務先施設名を自由入力に
    facilityNameFieldChanger('txt');
    // 勤務先施設名の切り替えボタンを非表示に
    facilityNameChangeBtnWrap.classList.remove('show');
    facilityNameChangeBtnWrap.classList.add('hide');
  } else {
    if (
      !qualificationPharmacistInput.checked &&
      !qualificationOtherInput.checked
    ) {
      inputInactivator([
        facilityPostalcodeInput,
        facilityStreetAddressInput,
        facilityTelInput,
        facilityDepartmentInput,
        facilityPositionInput,
      ]);
      titleInactivator([
        facilityPostalcodeTitle,
        facilityStreetAddressTitle,
        facilityTelTitle,
        facilityDepartmentTitle,
        facilityPositionTitle,
      ]);
      // 勤務先施設名(手入力)が未入力なら勤務先施設名を選択式に
      if (!facilityNameInputTxt.value) {
        facilityNameFieldChanger('select');
      }
      // 勤務先施設名の切り替えボタンを表示
      facilityNameChangeBtnWrap.classList.remove('hide');
      facilityNameChangeBtnWrap.classList.add('show');
    }
  }
}

/**
 * 勤務先施設名の手動フィールド切り替えボタンを切り替える
 */
if (facilityNameChangeBtn !== null) {
  facilityNameChangeBtn.addEventListener('click', facilityNameBtnChanger);
}
function facilityNameBtnChanger() {
  if (facilityNameChangeBtn.value == currentInputType.select) {
    facilityNameFieldChanger('txt');
  } else {
    facilityNameFieldChanger('select');
  }
}

/**
 * 利用規約同意に応じて仮登録ボタンの有効/無効を切り替え
 */
const agreementCheckBox = document.getElementById('entry_agreement');
if (agreementCheckBox !== null) {
  agreementCheckBox.addEventListener('change', toggleSubmitButton);
}
function toggleSubmitButton(e) {
  const button = document.getElementById('submit');
  button.disabled = !e.target.checked;
}

/**
 * メールアドレスやパスワードの値が確認フィールドと一緒かをチェックする
 */

// メールアドレスの照合
if (email !== null && emailConfirm !== null) {
  email.addEventListener('input', () => {
    collationer([email, emailConfirm]);
  });
  emailConfirm.addEventListener('input', () => {
    collationer([email, emailConfirm]);
  });
  email.addEventListener('change', toHalfSize);
  emailConfirm.addEventListener('change', toHalfSize);
}

// パスワードの照合
if (passwordRegister !== null && passwordRegisterConfirm !== null) {
  passwordRegister.addEventListener('input', () => {
    collationer([passwordRegister, passwordRegisterConfirm]);
  });
  passwordRegisterConfirm.addEventListener('input', () => {
    collationer([passwordRegister, passwordRegisterConfirm]);
  });
}

/**
 * 照合結果に応じてフォームの親クラスを書き換える
 * @param {HTMLElement[]} data
 */
function collationer(data) {
  const datum = data[0];
  const datumConfirm = data[1];

  // 何も入力されてないならリターン
  if (!datum.value || !datumConfirm.value) {
    return;
  }

  // アイコンのクラスを書き換え
  if (datum.value === datumConfirm.value) {
    datum.parentNode.classList.remove('collation--error');
    datumConfirm.parentNode.classList.remove('collation--error');
    datum.parentNode.classList.add('collation--pass');
    datumConfirm.parentNode.classList.add('collation--pass');
  } else {
    datum.parentNode.classList.remove('collation--pass');
    datumConfirm.parentNode.classList.remove('collation--pass');
    datum.parentNode.classList.add('collation--error');
    datumConfirm.parentNode.classList.add('collation--error');
  }
}

function toHalfSize() {
  let convertedEmail = '';
  let convertedEmailConfirm = '';
  // 入力値を半角に変換
  if (email.value) {
    convertedEmail = halfConverter(email.value);
    email.value = '';
    email.value = convertedEmail;
  }
  if (emailConfirm.value) {
    convertedEmailConfirm = halfConverter(emailConfirm.value);
    emailConfirm.value = '';
    emailConfirm.value = convertedEmailConfirm;
  }
}

/**
 * 診療科目selectboxをセットする
 */
function setClinicalDepartments() {
  const choices = clinicalDepartmentInput.choices;
  choices.clearChoices();
  choices.destroy();
  choices.init();
  choices.removeActiveItems();
  clinicalDepartmentInput.hidden = false;
  const clinicalAreaId = clinicalAreaInput.value;
  if (!clinicalAreaId) {
    return false;
  }
  const params = new URLSearchParams();
  params.append('clinical_area_id', clinicalAreaId);
  fetch(`/internal_api/clinical_departments?${params}`)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      const items = json.map((it) => {
        return { label: it[1], value: it[0] };
      });
      choices.setChoices(items, 'value', 'label', true);
    });
}

/**
 * 市区町村selectboxをセットする
 */
function setMunicipalities() {
  const choices = facilityMunicipalityInput.choices;
  choices.clearChoices();
  choices.destroy();
  choices.init();
  choices.removeActiveItems();
  facilityMunicipalityInput.hidden = false;
  const prefectureId = facilityPrefectureInput.value;
  if (!prefectureId) {
    return false;
  }
  const params = new URLSearchParams();
  params.append('prefecture_id', prefectureId);
  fetch(`/internal_api/municipalities?${params}`)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      const items = json.map((it) => {
        return { label: it[1], value: it[0] };
      });
      choices.setChoices(items, 'value', 'label', true);
    });
}

/**
 * 勤務先施設タイプの値を取得する (ラジオボタンで選択されたもの)
 * @return 勤務先施設タイプの値
 */
function getFacilityType() {
  const facilityTypeInput = facilityTypeInputs.find((it) => it.checked);
  return !facilityTypeInput ? null : facilityTypeInput.value;
}

/**
 * 勤務先施設selectboxをセットする
 */
function setFacilities() {
  const choices = facilityNameInputSelect.choices;
  choices.clearChoices();
  choices.destroy();
  choices.init();
  choices.removeActiveItems();
  facilityNameInputSelect.hidden = false;
  const facilityType = getFacilityType();
  const prefectureId = facilityPrefectureInput.value;
  const municipalityId = facilityMunicipalityInput.value;
  if (!facilityType || !prefectureId || !municipalityId) {
    return false;
  }
  const params = new URLSearchParams();
  params.append('type', facilityType);
  params.append('prefecture_id', prefectureId);
  params.append('municipality_id', municipalityId);
  fetch(`/internal_api/institutions?${params}`)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      const items = json.map((it) => {
        return { label: it[1], value: it[0] };
      });
      choices.setChoices(items, 'value', 'label', true);
    });
}

// apply choices to selects on the page (except registration date attributes)
function applyChoices() {
  const choicesOptions = {
    shouldSort: false,
    removeItemButton: true,
    searchResultLimit: 30, // default: 4
    searchFields: ['label'],
    searchPlaceholderValue: '検索',
    itemSelectText: '選択',
    placeholder: true,
    placeholderValue: '選択してください',
    noResultsText: '該当する項目が見つかりませんでした。',
    noChoicesText: '選択項目がありません。',
  };
  document.querySelectorAll('select:not([id*=registration_])').forEach((element) => {
    element.choices = new Choices(element, choicesOptions);
    element.hidden = false; // for focusable
  });
}

// 施設タイプの入力可否制御
function setFacilityTypeEnabled() {
  switch (true) {
    case qualificationPharmacistInput.checked:
      // [薬剤師]: 診療所以外有効
      inputActivator([facilityContents]);
      inputActivator(
        facilityTypeInputs.filter((it) => it !== facilityTypeClinicInput),
        'radio',
        true
      );
      inputInactivator([facilityTypeClinicInput], 'radio');
      titleActivator([facilityTitle], true);
      break;
    case qualificationOtherInput.checked:
      // [その他]: 全て無効
      inputInactivator([facilityContents]);
      inputInactivator(facilityTypeInputs, 'radio');
      titleInactivator([facilityTitle]);
      break;
    default:
      // [医師], [看護師・准看護師]: 薬局以外有効
      inputActivator([facilityContents]);
      inputActivator(
        facilityTypeInputs.filter((it) => it !== facilityTypePharmacyInput),
        'radio',
        true
      );
      inputInactivator([facilityTypePharmacyInput], 'radio');
      titleActivator([facilityTitle], true);
  }
}

function setDisplay(element, disabled = false) {
  if ((!element) || !(element instanceof HTMLElement)) return;
  element.style.display = (disabled ? 'none' : null);
}

function switchWrapper(wrapper, disabled = false) {
  if ((!wrapper) || !(wrapper instanceof HTMLElement)) return;

  setDisplay(wrapper, disabled);

  wrapper.querySelectorAll('input[type=radio]').forEach((it) => {
    it.disabled = disabled;
    it.required = !disabled;  // TODO: 有効≠必須?か再考
    if (disabled) it.checked = false;
  });
  wrapper.querySelectorAll('input[type=text]').forEach((it) => {
    it.disabled = disabled;
    it.required = !disabled;
    if (disabled) it.value = '';
  });
  wrapper.querySelectorAll('select').forEach((it) => {
    it.disabled = disabled;
    it.required = !disabled;
    if (disabled) it.options[0].selected = true;  // select the first (blank)
  });
}

function switchVerificationWrappers() {
  // 本人確認系項目群: 本人確認方法, 医籍登録番号, 医籍登録年月日
  const verificationMethodWrapper = document.getElementById('verification_method_wrapper');
  const registrationNumberWrapper = document.getElementById('registration_number_wrapper');
  const registrationDateWrapper = document.getElementById('registration_date_wrapper');
  const verificationWrappers = [
    verificationMethodWrapper,
    registrationNumberWrapper,
    registrationDateWrapper
  ].filter(Boolean);

  const isDoctor = qualificationDoctorInput.checked;
  verificationWrappers.forEach((it) => { switchWrapper(it, !isDoctor); });

  switchByVerificationMethod();
}

function switchByVerificationMethod() {
  const verificationMethodEnrollment = document.querySelector('[id$=verification_method_enrollment]');
  const verificationMethodRegistrationNumber = document.querySelector('[id$=registration_number]');
  const registrationNumberWrapper = document.getElementById('registration_number_wrapper');
  const registrationDateWrapper = document.getElementById('registration_date_wrapper');

  switch(true) {
    case (verificationMethodEnrollment.checked):
      // 本人確認方法: 在籍確認
      // 電話番号 必須化
      titleActivator([facilityTelTitle], true);
      inputActivator([facilityTelInput], 'input', true);
      setDisplay(facilityTelComment);
      // [医籍登録番号, 登録年月日] 非表示
      [registrationNumberWrapper, registrationDateWrapper].forEach((it) => { switchWrapper(it, true); });
      break;
    case (verificationMethodRegistrationNumber.checked):
      // 本人確認方法: 医籍登録確認
      // [医籍登録番号, 登録年月日] 表示
      [registrationNumberWrapper, registrationDateWrapper].forEach((it) => { switchWrapper(it); });
      // 電話番号 不要化
      titleInactivator([facilityTelTitle]);
      inputInactivator([facilityTelInput]);
      setDisplay(facilityTelComment, true);
      break;
    default:
      // 未選択
      // [医籍登録番号, 登録年月日] 非表示
      [registrationNumberWrapper, registrationDateWrapper].forEach((it) => { switchWrapper(it, true); });
      // 電話番号コメント 非表示
      setDisplay(facilityTelComment, true);
  }
}
